<template>
  <div>
    <!-- spinner -->
    <b-alert v-if="getErrorMsg" show variant="danger">{{
      getErrorMsg
    }}</b-alert>
    <div v-if="loadingProjects" class="text-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <div class="" v-else>
      <!-- page start-->
      <section class="card">
        <header class="card-header">
          All projects List
          <span class="pull-right">
            <a href="#" class=" btn btn-success btn-sm"> Create New Project</a>
          </span>
        </header>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <input
                type="text"
                @keyup="searchTable"
                v-model="searchString"
                placeholder="Search Here"
                class="input-sm form-control"
              />
            </div>
          </div>
        </div>
        <table class="table table-hover p-table" id="projectTable">
          <thead>
            <tr>
              <th>No</th>
              <th>Project Name</th>
              <th>Description</th>
              <!-- <th>Customer</th> -->
              <!-- <th>Project Status</th> -->
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pro in allProjects" :key="pro.id">
              <td class="">
                {{ pro.projectNo }}
              </td>
              <td class="p-name">
                <a href="">{{ pro.name }}</a>
                <br />
                <small>Created 27.11.2014</small>
              </td>
              <td class="">
                {{ pro.description }}
              </td>
              <!-- <td class="">
                              {{ pro.customer }}
                          </td> -->
              <!-- <td>
                              <span class="badge " >{{ pro.isActive }}</span>
                          </td> -->
              <td>
                <router-link
                  class="btn btn-info btn-sm mr-2"
                  :to="'/admin/project/' + pro.id"
                  >{{ $t("items.view") }}</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <!-- page end-->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
// import 'bootstrap/dist/css/bootstrap.css'

export default {
  name: "projects",
  methods: {
    ...mapActions(["getProjects"]),
    searchTable() {
      // Declare variables
      var filter, table, tr, td, i, txtValue;

      filter = this.searchString.toUpperCase();
      table = document.getElementById("projectTable");
      tr = table.getElementsByTagName("tr");

      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[0];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    }
  },
  computed: mapGetters(["allProjects", "getErrorMsg", "loadingProjects"]),
  data() {
    return {
      searchString: ""
    };
  },
  created() {
    this.getProjects();
  }
};
</script>

<style scoped>
h3 {
  text-align: left;
}
</style>
